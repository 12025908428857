.App {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  color: #37474F;
}

.App-header {
  padding: 50px 20px 40px;
}

.App-header > img {
  max-height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.App-main {
  padding: 20px 20px 100px;
}

.App-table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.App-table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.App-table tr {
  background: #fff;
  padding: .35em;
}

.App-table th,
.App-table td {
  padding: .925em;
}

.App-table th {
  font-size: .85em;
  letter-spacing: .1em;
  font-weight: 400;
  background: #ECEFF1;
}

.DateInput_displayText {
  color: #37474F;
}

.CalendarDay__selected {
  background: #3498DB !important;
  border-color: #3498DB !important;
}

.DateInput_displayText__focused {
  background: #CFD8DC;
  border-color: #CFD8DC;
}

.App-top-selector {
  list-style: none;
  display: flex;
  padding: 0;
}

.App-top-selector-item {
  display: block;
  background: #ECEFF1;
  padding: 1rem;
  cursor: pointer;
  font-weight: 700;
  margin-right: 10px;
  border: 1px solid #CFD8DC;
}

.App-top-selector-item.is-active {
  background: #3498DB;
  color: #fff;
}

.csv-export {
  color: #375a7f;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  padding-right: 13px;
  transition: color 0.2s ease;
}

.csv-export:after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-left: 5px solid #375a7f;
  content: '';
  position: absolute;
  right: 0;
  top: 4px;
  transform: translateX(0);
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.csv-export:hover {
  color: #3498DB;
}

.csv-export:hover:after {
  border-left: 5px solid #3498DB;
  transform: translateX(3px);
}

@media screen and (max-width: 700px) {
  .App-header {
    padding: 40px 20px 20px;
  }

  .App-header > img {
    height: 20px;
  }

  .App-table {
    border: 0;
  }

  .App-table caption {
    font-size: 1.3em;
  }

  .App-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .App-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  .App-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .App-table td:before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .App-table td:last-child {
    border-bottom: 0;
  }

  .App-top-selector {
    flex-direction: column;
  }

  .App-top-selector-item {
    margin: 5px 0;
  }
}

.App-footer {
  padding: 20px 20px;
  text-align: center;
}
