body {
  margin: 0;
  padding: 0;

  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  letter-spacing: 0.05em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 13px;

  background: #FAFAFA;
}
